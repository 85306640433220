import { useQuery } from '@tanstack/vue-query'
import api from '@/services/api.ts'
import { computed } from 'vue'
import { tiersObj } from '@/const/tiers.ts'

export const useConfig = () => {

  const { data: lockConfigData, refetch, isLoading } = useQuery({
    queryKey: ['lockConfig'],
    queryFn: () => {
      return api.getConfig()
    },
    initialData: () => null,
  })

  const sortedValues = computed(() => {
    if ( !lockConfigData.value ) return []
    return Object.values(lockConfigData.value.tiers || {})
      .map(value => parseFloat(value.toString()))
      .sort((a, b) => b - a)
      .filter((value, index, self) => self.indexOf(value) === index && value > 0)
  })
  const tiers = computed(() => {
    const tiersTemp = tiersObj.slice(0, sortedValues.value.length)
    return sortedValues.value.map((value, index) => {
      return {
        ...tiersTemp[index],
        minValue: value,
      }
    })
  })

  return {
    lockConfig: lockConfigData,
    refetchLockConfig: refetch ,
    isLoadingLockConfig: isLoading,
    tiers,
  }
}