import tier1 from '@/assets/images/tiers/tier1.png'
import tier2 from '@/assets/images/tiers/tier2.png'
import tier3 from '@/assets/images/tiers/tier3.png'
import tier4 from '@/assets/images/tiers/tier4.png'
import tier5 from '@/assets/images/tiers/tier5.png'
import tier0 from '@/assets/images/tiers/tier0.png'

export type Tier = {
    id: string
    name: string
    imageUrl: string
    imageClass: string
    minValue: number
    description: string
    range: string
}

export const defaultTier: Tier = { id: 'tier0', name: '-', imageUrl: tier0, imageClass: '', minValue: 0, description: '', range: '100 power miles' }

export const tiersObj: Tier[] = [
  { id: 'tier1',name: 'I', imageUrl: tier1, imageClass: 'w-[110px] -mlc-2.5', minValue: 0, description: 'Top 1%', range: 'top 1%' },
  { id: 'tier2',name: 'II', imageUrl: tier2, imageClass: 'w-[130px] -mlc-[5px] -mt-2', minValue: 0, description: 'Next 10%', range: 'top 10%' },
  { id: 'tier3',name: 'III', imageUrl: tier3, imageClass: 'w-[127px] -mlc-[3px] -mt-3', minValue: 0, description: 'Next 30%', range: 'top 30%' },
  { id: 'tier4',name: 'IV', imageUrl: tier4, imageClass: 'w-[101px]', minValue: 0, description: 'Next 20%', range: 'Top 50%' },
  { id: 'tier5', name: 'V', imageUrl: tier5, imageClass: 'w-[101px] ml-c2 -mt-[3px]', minValue: 0, description: 'Last 50%', range: 'bottom 50%' },
]