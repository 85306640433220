<template>
  <footer class="flex flex-col lg:flex-row lg:gap-8 justify-between items-center">
    <div class="max-w-[587px] opacity-50 text-center text-white text-xs font-medium leading-none lg:order-2 flex-1 max-lg:mb-8">
      Investment in digital assets carries a risk of total or partial capital loss and a risk of significant volatility potentially inappropriate for retail clients. Only risk capital that you are willing to lose.
    </div>
    <div class="h-[22px] justify-start items-center gap-8 inline-flex lg:order-3 max-lg:mb-4">
      <a
        :href="termsUrl"
        class="opacity-70 hover:opacity-100 text-white hover:text-white text-sm font-medium leading-snug"
        target="_blank"
      >Terms and Conditions</a>
      <a
        :href="privacyUrl"
        class="opacity-70 hover:opacity-100 text-white hover:text-white text-sm font-medium leading-snug"
        target="_blank"
      >Privacy Policy</a>
    </div>
    <p class="opacity-70 text-white text-sm font-medium leading-snug lg:order-1">
      &copy;{{ currentYear }} Lingo. All rights reserved.
    </p>
  </footer>
</template>
<script setup lang="ts">
import { LingoRouteName } from '@/router/routes.ts'
import { useRouter } from 'vue-router'
import { computed } from 'vue'

const router = useRouter()

const termsUrl = computed(() => router.resolve({ name: LingoRouteName.TERMS_AND_CONDITIONS }).href)
const privacyUrl = computed(() => router.resolve({ name: LingoRouteName.PRIVACY_POLICY }).href)

// get current year
const currentYear = new Date().getFullYear()
</script>
<style scoped>

</style>