<template>
  <div class="h-full w-full min-h-svh flex flex-col p-3">
    <BaseTopBar class="mb-2 md:mb-10">
      <template #right>
        <div class="flex flex-col lg:flex-row space-1 gap-5 max-md:mt-1">
          <BaseButton
            v-if="referralCode"
            style-type="tertiary"
            class="max-md:hidden whitespace-pre max-lg:order-2"
            @click="showInviteModal = true"
          >
            Refer & Earn
          </BaseButton>
          <DisconnectWallet
            v-if="apiToken"
            class="max-lg:order-1"
          />
          <ConnectWalletButton
            v-if="!apiToken && nonAuthRoute"
            button-style="secondary"
            class="max-md:px-2"
          />
        </div>
      </template>
    </BaseTopBar>
    <div class="flex-1 flex">
      <slot />
    </div>
    <BaseFooter class="px-4 md:px-9 py-4" />
    <InviteModal
      v-if="referralCode"
      :show="showInviteModal"
      :referral-code="referralCode"
      @hide="showInviteModal = false"
    />
  </div>
</template>
<script setup lang="ts">
import BaseTopBar from '@/components/layout/BaseTopBar.vue'
import BaseFooter from '@/components/layout/BaseFooter.vue'
import ConnectWalletButton from '@/components/ConnectWalletButton.vue'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import DisconnectWallet from '@/components/DisconnectWallet.vue'
import { apiToken } from '@/composables/auth.ts'
import { LingoRouteName } from '@/router/routes.ts'
import BaseButton from '@/components/base/BaseButton.vue'
import InviteModal from '@/components/InviteModal.vue'
import { useGetMe } from '@/composables/get-me.ts'

const route = useRoute()
const nonAuthRoute = computed(() => route.name === LingoRouteName.START_EARNING || route.name === LingoRouteName.REWARDS || route.name === LingoRouteName.BRIDGE || route.name === LingoRouteName.BUY )
const showInviteModal = ref(false)

const { referralCode } = useGetMe()

</script>