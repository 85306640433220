export const walletTruncate = (input: string) => {
  if (!input) return ''
  const separator = '...'

  if (input.length <= 13) return input

  return input.substr(0, 6) +
        separator +
        input.substr( -6)
}

export function formatNumber(value: number, decimals: number): number {
  const formatted = value.toFixed(decimals)
  return parseFloat(formatted)
}
export const formatNumberToUS = (x: number | string | undefined | null): number | string => {
  if (!x || Number(x) < 0) {
    return 0
  }
  const numberValue = Number(x)
  const formattedNumber = formatNumber(numberValue, 2)
  return formattedNumber.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
}

export function formatCamelCase(input: string): string {
  return input
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    .trim()
}

export function redirectToService(baseUrl: string, params: Record<string, string | number>): void {
  const queryString = new URLSearchParams(params as Record<string, string>).toString()
  const fullUrl = `${baseUrl}?${queryString}`

  window.open(fullUrl, '_blank')
}