import { useLocalStorage } from '@vueuse/core'
import { watch } from 'vue'
import flagsmith from 'flagsmith'
import * as Sentry from '@sentry/vue'

export const apiToken = useLocalStorage('apiToken', '')
export const walletConnected = useLocalStorage('walletConnected', '')
export const referrerCode = useLocalStorage<string>('referrerCode', null)

watch(walletConnected, async (value) => {
  if (!value) {
    Sentry.setUser(null)
    return
  }
  await flagsmith.identify(value)
  Sentry.setUser({
    id: value,
  })
}, { immediate: true })

export function hasTokenExpired() {
  const base64Url = apiToken.value.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  const payload = JSON.parse(jsonPayload) as { exp: number, version: number }
  if (payload.version !== 2) {
    return true
  }
  const threeHours = 3 * 60 * 60 * 1000
  return (payload.exp - 7200) * 1000 < Date.now() + threeHours
}
