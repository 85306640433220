import { connect, disconnect, getAccount, signMessage } from '@wagmi/core'
import { useWeb3Modal, useWeb3ModalEvents } from '@web3modal/wagmi/vue'
import { config, metadata } from './wallet-init.ts'
import { callApi } from '@/services/call-api.ts'
import { apiToken, referrerCode, walletConnected } from '@/composables/auth.ts'
import { watch } from 'vue'
import mixpanel from 'mixpanel-browser'
import flagsmith from 'flagsmith'
import { useSnapshot } from '@/composables/contracts/snapshot.ts'
import { coinbaseWallet } from '@wagmi/connectors'

export const useWalletAuth = () => {
  const { snap } = useSnapshot()
  async function authenticate() {
    const account = getAccount(config)
    const { message, nonce } = await callApi<{ message: string, nonce: string }>({
      path: '/api/auth/signature/message',
      method: 'POST',
      body: { message: 'Please sign this message to login to Lingo!', walletAddress: account.address },
    })
    const signature = await signMessage(config, {
      message: message,
    })
    const { token } = await callApi<{ token: string }>({
      path: '/api/auth/signature/verify',
      method: 'POST',
      body: { nonce, signature, referrerCode: referrerCode.value },
    })
    apiToken.value = token
    walletConnected.value = account.address
    mixpanel.track('Wallet Connected')
  }

  async function connectAndAuthenticate() {
    const account = getAccount(config)
    if (account.address) {
      await authenticate()
      return
    }
    const { open } = useWeb3Modal()
    await open({ view: 'Connect' })
    const events = useWeb3ModalEvents()
    const unwatch = watch(events, events => {
      const eventName = events.data.event
      if (eventName === 'MODAL_CLOSE') {
        unwatch()
      }
      if (eventName === 'CONNECT_SUCCESS') {
        unwatch()
        authenticate()
      }
    })
  }

  async function logout() {
    try {
      await disconnect(config)
    } catch (e) {
      // In case wallet is not connected, it will throw an error
    }
    apiToken.value = ''
    walletConnected.value = ''
    snap.value = null
    localStorage.clear()
    await flagsmith.logout()
  }

  async function createSmartWallet (){
    await connect(config, { connector: coinbaseWallet({
      appName: metadata?.name ?? 'Unknown',
      appLogoUrl: metadata?.icons[0] ?? 'Unknown',
      enableMobileWalletLink: true,
    }) })
    await authenticate()
  }

  return {
    login: connectAndAuthenticate,
    logout: logout,
    createSmartWallet,
  }
}
