<template>
  <div
    class="border-2 border-[#A233D5] bg-[#A233D5]/20 flex gap-x-3 justify-between p-0.5 pl-4 items-center rounded-lg cursor-pointer"
    @click="copyLink"
  >
    <div class="font-korolev font-bold tracking-[1px] uppercase">
      {{ text }}
    </div>
    <BaseButton>
      {{ copySuccessful ? 'copied' : copyLabel }}
    </BaseButton>
  </div>
</template>
<script setup lang="ts">
import { useClipboard } from '@/composables/clipboard'
import { computed, ref } from 'vue'
import { cleanUpUrl } from '@/composables/url'
import BaseButton from '@/components/base/BaseButton.vue'

const props = withDefaults(defineProps<{
  text: string
  copyValue?: string
  image?: string
  cleanUrl?: boolean
  copyLabel?: string
}>(), {
  image: '',
  cleanUrl: true,
  copyLabel: 'copy',
  copyValue: undefined,
})

const emit = defineEmits(['onCopy'])

const text = computed(() => props.cleanUrl ? cleanUpUrl(props.text) : props.text)

const { copyToClipboard } = useClipboard()
const copySuccessful = ref(false)
const copyLink = () => {
  emit('onCopy')
  if (props.copyValue) {
    copyToClipboard(props.copyValue)
  } else {
    copyToClipboard(text.value)
  }
  copySuccessful.value = true
  setTimeout(() => {
    copySuccessful.value = false
  }, 3000)
}
</script>
<style scoped>

</style>