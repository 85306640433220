import api from '@/services/api.ts'
import { walletConnected } from '@/composables/auth.ts'
import { useQuery } from '@tanstack/vue-query'
import { computed } from 'vue'

export const useGetMe = () => {

  const { data, refetch: refetchGetMe, isLoading: loadingGetMe } = useQuery({
    queryKey: ['meData', walletConnected],
    queryFn: async () => {
      return (await api.getMe()).me
    },
    initialData: null,
    enabled: computed(() => !!walletConnected.value),
  })

  const referralCode = computed(() => {
    return data.value?.profile?.referralCode
  })

  return {
    meData: data,
    refetchGetMe,
    loadingGetMe,
    referralCode,
  }
}
