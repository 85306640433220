import { createApp } from 'vue'
import './index.css'
import './style.css'
import App from './App.vue'
import router from './router'
import * as Sentry from '@sentry/vue'
import { appEnv, env } from '@/env.ts'
import mixpanel from 'mixpanel-browser'
import vSelect from 'vue-select'
import { VueQueryPlugin } from '@tanstack/vue-query'
import { referrerCode } from '@/composables/auth.ts'

const app = createApp(App)
const debugCode = new URL(window.location.href).searchParams.get('debug')
if (debugCode) {
  sessionStorage.setItem('debugCode', debugCode)
}
if (env.sentryDsn) {
  Sentry.init({
    app,
    dsn: env.sentryDsn,
    maxValueLength: 4096,
    allowUrls: [new RegExp('^https://app.lingocoin.io/'), new RegExp('^https://lingo-staking-webapp-dev.pages.dev/')],
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 0.1, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/api-(dev|prod).lingo.tropee.com/],
    // Session Replay
    replaysSessionSampleRate: sessionStorage.getItem('debugCode') ? 1 : 0,
    replaysOnErrorSampleRate: sessionStorage.getItem('debugCode') ? 1 : 0,
    environment: appEnv,
  })
  if (sessionStorage.getItem('debugCode')) {
    Sentry.setExtra('debugCode', sessionStorage.getItem('debugCode'))
  }
}
if (env.mixpanelToken) {
  mixpanel.init(env.mixpanelToken, { persistence: 'localStorage' })
} else {
  mixpanel.init('test', { test: true })
}
app.use(router)
app.use(VueQueryPlugin)
app.component('VSelect', vSelect)

app.use(VueQueryPlugin, {
  enableDevtoolsV6Plugin: true,
})

if (!referrerCode.value) {
  const urlParams = new URLSearchParams(window.location.search)
  const referrerCodeRef = urlParams.get('ref')
  if (referrerCodeRef) {
    referrerCode.value = referrerCodeRef
  }
}

app.mount('#app')
