<template>
  <BaseModal
    :show="show"
    close-button
    @hide="emit('hide')"
  >
    <div class="flex flex-col md:flex-row max-md:items-center gap-4 md:gap-8">
      <div class="inviteImage max-w-[230px] md:max-w-[334px] h-auto rounded-lg overflow-hidden relative">
        <div class="absolute inset-0 border border-white/20 rounded-lg " />
        <img
          :src="inviteImage"
          alt="Invite and earn"
          class="w-full h-full object-cover"
        >
      </div>
      <div class="flex flex-col justify-center py-8">
        <h2 class="text-2xl leading-7 font-medium tracking-[-1px] uppercase mb-6">
          Invite & earn your <PowerMiles
            class="leading-7"
            image-class="!h-6 ml-2"
          />
        </h2>
        <ol class="text-sm font-medium text-white opacity-80 mb-8">
          <li class="mb-5 flex items-start gap-3">
            <div class="h-5 flex flex-col justify-center">
              <div class="w-4 h-4 relative -rotate-45 rounded-[3px] border border-[#dc53fb]">
                <div class="text-[#dc53fb] text-xs font-semibold rotate-45 uppercase leading-[14px] text-center">
                  1
                </div>
              </div>
            </div>
            <span class="max-w-[80%]">Copy the unique link.</span>
          </li>
          <li class="mb-5 flex items-start gap-3">
            <div class="h-5 flex flex-col justify-center">
              <div class="w-4 h-4 relative -rotate-45 rounded-[3px] border border-[#dc53fb]">
                <div class="text-[#dc53fb] text-xs font-semibold rotate-45 uppercase leading-[14px] text-center">
                  2
                </div>
              </div>
            </div>
            <span class="max-w-[80%]">Share with friends.</span>
          </li>
          <li class="flex items-start gap-3">
            <div class="h-5 flex flex-col justify-center">
              <div class="w-4 h-4 relative -rotate-45 rounded-[3px] border border-[#dc53fb]">
                <div class="text-[#dc53fb] text-xs font-semibold rotate-45 uppercase leading-[14px] text-center">
                  3
                </div>
              </div>
            </div>
            <span class="max-w-[80%]">Receive 5% of airmiles they generate via $LINGO staking lifetime.</span>
          </li>
          <li>
            <p class="text-xs my-2 ml-7 max-w-[80%]">
              Reminder: Make sure to stake to start earning Power Miles from your referrals!
            </p>
          </li>
        </ol>
        <p class="text-xs leading-[18px] font-medium text-white opacity-80 mb-2">
          Your unique link:
        </p>
        <CopyBox
          :text="`https://app.lingocoin.io/?ref=${referralCode}`"
        />
      </div>
    </div>
    <div
      v-if="referral && referral.count > 0"
      class="p-4 mt-6 md:m-8 bg-white/[12%] rounded-lg "
    >
      <h3 class="text-xl uppercase font-medium -tracking-[1px]">
        Referrals summary
      </h3>
      <div class="flex flex-col mt-1">
        <div class="flex items-center justify-between border-b border-white/20 py-3">
          <span class="text-base font-medium text-white/80">
            Referred wallets staked $LINGO:
          </span>
          <span class="text-xl font-medium text-white">
            {{ referral.count }}
          </span>
        </div>
        <div class="flex items-center justify-between pt-3">
          <span class="text-base font-medium text-white/80">
            Earned Power Miles
          </span>
          <span class="text-xl font-medium text-white flex gap-1 items-center">
            <img
              :src="powerMileToken"
              alt="token icon"
              class="size-6"
            >
            <span>{{ formatNumberToUS(referral.power) }}</span>
          </span>
        </div>
      </div>
    </div>
  </BaseModal>
</template>
<script setup lang="ts">
import CopyBox from '@/components/base/CopyBox.vue'
import inviteImage from '@/assets/images/invite-earn.png'
import PowerMiles from '@/components/PowerMiles.vue'
import BaseModal from '@/components/base/BaseModal.vue'
import powerMileToken from '@/assets/images/power-token.png'
import { useStakes } from '@/composables/contracts/stakes.ts'
import { formatNumberToUS } from '../composables/helpers.ts'

defineProps<{
  show: boolean
  referralCode: string
}>()

const emit = defineEmits<{
    (event: 'hide'): void
}>()

const { referral } = useStakes()

</script>
<style scoped>
.inviteImage {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%), url("../assets/images/fire-ball.png") lightgray 50% / cover no-repeat;
}
</style>