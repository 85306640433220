import { LOCK_DURATION_ID } from '@/types/staking.ts'
import { transactionError, useStatus } from '@/composables/contracts/status.ts'
import { readContract, waitForTransactionReceipt, writeContract } from '@wagmi/core'
import { config } from '@/composables/wallet/wallet-init.ts'
import stakingContractDefinition from '@/abi/TokenStaking.json'
import { parseEther } from 'viem'
import { walletConnected } from '@/composables/auth.ts'
import { env } from '@/env.ts'
import api from '@/services/api.ts'
import { useApproval } from '@/composables/contracts/approval.ts'
import { useConfig } from '@/composables/config.ts'

export const useStaking = () => {
  const { lockConfig } = useConfig()
  const { updateStatus, handleTransactionError } = useStatus()
  const { approve } = useApproval()

  async function stake(amount: number | null, lockingPeriod: LOCK_DURATION_ID) {
    transactionError.value = {
      text: null,
      value: null,
    }
    try {
      await updateStatus('stake', 'global', { loading: true, success: false, error: false })
      const approval = await approve()

      if (!approval) {
        throw new Error('Approval failed')
      }

      if (!amount || !lockingPeriod) {
        throw new Error('Amount and lock period are required')
      }
      const durationIndex = lockConfig.value?.lockDurations[lockingPeriod].index
      const expectedDuration = lockConfig.value?.lockDurations[lockingPeriod].lockDurationBlocks

      const txHash = await writeContract(config, {
        abi: stakingContractDefinition.abi,
        functionName: 'stake',
        args: [parseEther(String(amount)), durationIndex, expectedDuration, walletConnected.value],
        address: env.contracts.base.stakingAddress,
      })

      await waitForTransactionReceipt(config, { hash: txHash })
      await updateStatus('stake', 'global', { success: true })
      await api.checkTransaction(txHash)
      return true
    } catch (error) {
      console.error('Error in stake:', error)
      await updateStatus('stake', 'global', { error: true })
      handleTransactionError(error)
      return false
    } finally {
      await updateStatus('stake', 'global', { loading: false })
    }
  }

  async function getStakes() {
    const a = await readContract(config, {
      abi: stakingContractDefinition.abi,
      functionName: 'getStakes',
      args: [walletConnected.value],
      address: env.contracts.base.stakingAddress,
    }) as [{ amount: bigint, unlockBlock: bigint }]
    return a
  }

  async function unstake(amount: number, unlockBlock: string) {
    transactionError.value = {
      text: null,
      value: null,
    }
    try {
      await updateStatus('unstake', 'global', { loading: true, success: false, error: false })
      const stakes = await getStakes()
      if (!stakes) {
        throw new Error('No stakes found')
      }
      const index = stakes.findIndex(s => {
        return s.amount === parseEther(String(amount)) && s.unlockBlock === BigInt(unlockBlock)
      })
      if (index === -1) {
        throw new Error('Stake not found')
      }

      const txHash = await writeContract(config, {
        abi: stakingContractDefinition.abi,
        functionName: 'unstake',
        args: [index],
        address: env.contracts.base.stakingAddress,
      })

      await waitForTransactionReceipt(config, { hash: txHash })
      await updateStatus('unstake', 'global', { success: true })
      await api.checkTransaction(txHash)
      return true
    } catch (error) {
      await updateStatus('unstake', 'global', { error: true })
      handleTransactionError(error)
      console.error('Error in unstake:', error)
      return false
    } finally {
      await updateStatus('unstake', 'global', { loading: false })
    }
  }

  async function renewStake(amount: number, lockingPeriod: LOCK_DURATION_ID, unlockBlock: string) {
    await updateStatus('stake', 'global', { loading: true, success: false, error: false })
    await updateStatus('unstake', 'global', { loading: true, success: false, error: false })
    await updateStatus('renew', 'global', { loading: true, success: false, error: false })
    transactionError.value = {
      text: null,
      value: null,
    }
    try {
      const unstakeResponse = await unstake(amount, unlockBlock)
      if (!unstakeResponse) {
        await updateStatus('unstake', 'global', { error: true })
        throw new Error('Unstake failed')
      }
      await updateStatus('unstake', 'global', { success: true })
      const stakeResponse = await stake(amount, lockingPeriod)
      if (!stakeResponse) {
        await updateStatus('stake', 'global', { error: true })
        throw new Error('Stake failed')
      }
      await updateStatus('stake', 'global', { success: true })
      await updateStatus('renew', 'global', { success: true })
      return true
    } catch (error) {
      await updateStatus('renew', 'global', { error: true })
      handleTransactionError(error)
      console.error('Error in renewStake:', error)
      return false
    } finally {
      await updateStatus('unstake', 'global', { loading: false })
      await updateStatus('stake', 'global', { loading: false })
      await updateStatus('renew', 'global', { loading: false })
    }
  }

  return {
    stake,
    unstake,
    renewStake,
  }
}