<template>
  <Teleport
    to="#app"
    :disabled="disableTeleport"
  >
    <transition
      name="fade"
      :css="!disableTransition"
    >
      <div
        v-show="show"
        class="modal fixed inset-0 cursor-default z-[90] flex justify-center"
        :class="[wrapperClass, {'items-center': !isMobile && !isForceMobile}]"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="fixed inset-0 transition-opacity z-[91]"
          :class="[layoutBgClass || 'bg-black bg-opacity-60']"
          @click="modalOutsideClicked"
          aria-hidden="true"
        />
        <transition
          name="slide"
          :css="(isMobile || isForceMobile) && !disableTransition"
        >
          <div
            v-if="show"
            class="modal-container overflow-y-auto flex bg-[#303030] md:w-[90%] max-h-[95%] rounded-t-lg z-[92] fixed bottom-0 p-4 md:p-2"
            :class="[containerClass, { 'max-w-[841px] rounded-b-lg bottom-auto': !isMobile && !isForceMobile }]"
            :style="sheetStyle"
          >
            <div
              ref="contentSlot"
              :class="[contentClass, 'flex-1 h-full']"
            >
              <slot />
            </div>
            <img
              v-if="closeButton"
              class="w-6 h-6 mt-4 mr-4 cursor-pointer absolute top-0 right-0"
              :class="closeClass"
              :src="x"
              alt="Close"
              @click="$emit('hide')"
            >
          </div>
        </transition>
      </div>
    </transition>
  </Teleport>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
import useIsMobile from '@/composables/is-mobile'
import x from '@/assets/icons/x.svg'

const props = withDefaults(defineProps<{
  show?: boolean
  closeButton?: boolean
  closeClass?: string
  wrapperClass?: string
  contentClass?: string
  containerClass?: string
  layoutBgClass?: string
  closeOnClickOutside?: boolean
  disableTeleport?: boolean
  isForceMobile?: boolean
  disableTransition?: boolean
}>(), {
  show: false,
  closeButton: false,
  closeClass: '',
  wrapperClass: '',
  contentClass: '',
  containerClass: '',
  layoutBgClass: '',
  closeOnClickOutside: true,
  disableTeleport: false,
  isForceMobile: false,
  disableTransition: false,
})

const emit = defineEmits(['hide', 'back'])

const isMobile = useIsMobile()
const contentSlot = ref<HTMLElement | null>(null)
const appHeight = ref('100svh')

const sheetStyle = ref({ '--app-height': appHeight.value })

function setAppHeightOnResize(event: Event) {
  if (!event.target || !('height' in event.target) || !event.target?.height || isNaN(event.target.height as number)) return
  appHeight.value = `${event.target.height}px`
}

onMounted(() => {
  if (props.show) {
    modalOpenEffects()
  }
  if (!visualViewport) return
  visualViewport.addEventListener('resize', setAppHeightOnResize)
})

onBeforeUnmount(() => {
  modalHiddenEffects()
  if (!visualViewport) return
  visualViewport.removeEventListener('resize', setAppHeightOnResize)
})

function modalOpenEffects() {
  document.body.classList.add('!overflow-hidden')
}

function modalHiddenEffects() {
  //Check if other modal is still open:
  if (document.querySelectorAll('.modal-container').length > 1) return
  // When the modal is hidden...
  document.body.classList.remove('!overflow-hidden')
}

const modalOutsideClicked = () => {
  if (props.closeOnClickOutside) {
    emit('hide')
  }
}

// when modal is open, disable scrolling on the body
watch(() => props.show, (show) => {
  if (show) {
    modalOpenEffects()
  } else {
    modalHiddenEffects()
  }
})

</script>
<style scoped lang="scss">
.fade-enter-active {
  transition: opacity 0.15s ease-in;
  opacity: 1;
}

.fade-leave-active {
  transition: opacity 0.15s ease-out;
  transition-delay: 0.15s;
  opacity: 1;
}

.fade-enter-from {
  opacity: 0;
}

.fade-leave-to {
  opacity: 0;
}

.slide-enter-active {
  transition: transform 0.25s ease-in;
  transform: translateY(0);
}

.slide-leave-active {
  transition: transform 0.25s ease-out;
  transform: translateY(0);
  transition-property: transform, opacity;
}

.slide-enter-from {
  transform: translateY(100%);
}

.slide-leave-to {
  transform: translateY(100%);
}

@media screen and (max-width: 768px) {
  .modal-container {
    max-height: calc(var(--app-height) - 32px);
  }
}
</style>